export default [
  {
    path: "/course/midwife/:country?",
    name: "courses_midwife",
    component: (): Promise<typeof import("*.vue")> =>
      import("@/pages/web/views/Course/CourseMidwife.vue"),
    meta: { public: true, country: "all" },
  },
  {
    path: "/course/doctor",
    name: "courses_doctor",
    component: (): Promise<typeof import("*.vue")> =>
      import("@/pages/web/views/Course/CourseDoctor.vue"),
    meta: { public: true },
  },
  {
    path: "/course/onsite",
    name: "courses_onsite",
    component: (): Promise<typeof import("*.vue")> =>
      import("@/pages/web/views/Course/CourseOnSite.vue"),
    meta: { public: true },
  },
  {
    path: "/course/inhouse",
    name: "courses_inhouse",
    component: (): Promise<typeof import("*.vue")> =>
      import("@/pages/web/views/Course/CourseInhouse.vue"),
    meta: { public: true },
  },
];
