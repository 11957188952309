import Vue from "vue";
import App from "@/pages/web/App.vue";
import CountryFlag from "vue-country-flag";
import VueCurrencyFilter from "vue-currency-filter";
import VueAxios from "vue-axios";
import VueRouter from "vue-router";
import vuetify from "@/plugins/vuetify";
import VueMoment from "vue-moment";
import router from "./router";
import axios, { Axios, AxiosError } from "axios";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import VueToast from "vue-toast-notification";
import "vue-toast-notification/dist/theme-sugar.css";
import store from "@/store";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
  extend,
  ValidationProvider,
  ValidationObserver,
  setInteractionMode,
} from "vee-validate";
import {
  required,
  email,
  numeric,
  double,
  min,
  max,
  confirmed,
  required_if,
  regex,
} from "vee-validate/dist/rules";

import VueCookies from "vue-cookies";

Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);
Vue.component("country-flag", CountryFlag);
Vue.config.productionTip = false;

Vue.use(VueToast, {
  position: "top-right",
});
Vue.use(VueRouter);
Vue.use(VueCookies, { domain: process.env.VUE_APP_BASE_DOMAIN });

Vue.use(VueMoment);
Vue.use(VueCurrencyFilter, {
  symbol: "€",
  thousandsSeparator: ".",
  fractionCount: 2,
  fractionSeparator: ",",
  symbolPosition: "end",
  symbolSpacing: true,
});

axios.defaults.baseURL = process.env.VUE_APP_API_URI;

import authModule from "@/store/modules/auth";

axios.interceptors.request.use((request) => {
  if (request.headers) {
    if (
      request.url != "/api/token/refresh" &&
      authModule.getAuth?.token &&
      authModule.isLoggedIn
    ) {
      request.headers[
        "Authorization"
      ] = `Bearer ${authModule.getAuth.token.jwt}`;
    } else {
      request.headers["Authorization"] = "";
    }

    if (Vue.$cookies.isKey("IMPERSONATE")) {
      console.log(Vue.$cookies.get("IMPERSONATE").email);
      request.headers["X-Switch-User"] = Vue.$cookies.get("IMPERSONATE").email;
    }
  }

  return request;
});

const refreshAuthLogic = (failedRequest: AxiosError) =>
  authModule
    .refresh()
    .then(() => {
      if (failedRequest.response?.config.headers) {
        failedRequest.response.config.headers["Authorization"] =
          "Bearer " + authModule.getAuth?.token?.jwt;
      }

      return Promise.resolve();
    })
    .catch(() => {
      authModule.logout();
      router.go(0);
    });

createAuthRefreshInterceptor(axios, refreshAuthLogic);
Vue.use(VueAxios, axios);

setInteractionMode("eager");
extend("required", {
  ...required,
  message: "Das Feld darf nicht leer sein.",
});
extend("required_if", {
  ...required_if,
  message: "Das Feld darf nicht leer sein.",
});
extend("email", {
  ...email,
  message: "Bitte geben Sie eine gültige E-Mail-Adresse ein.",
});
extend("numeric", {
  ...numeric,
  message: "Bitte geben Sie nur Ziffern ein.",
});
extend("double", {
  ...double,
  message: "Bitte geben Sie nur Ziffern ein.",
});
extend("min", {
  ...min,
  params: ["length"],
  message: "Das Feld muss mindestens {length} Zeichen lang sein.",
});
extend("max", {
  ...max,
  params: ["length"],
  message: "Das Feld darf höchstens {length} Zeichen lang sein.",
});
extend("confirmed", {
  ...confirmed,
  message: "Die eingegebenen Werte sind nicht identisch.",
});
extend("regex", {
  ...regex,
  message: "Der eingegebene Wert entspricht nicht den Vorgaben.",
});

new Vue({
  vuetify,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
