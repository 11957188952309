export default [
  {
    path: "/myaccount",
    name: "myaccount",
    component: (): Promise<typeof import("*.vue")> =>
      import("@/pages/web/views/MyAccount/MyAccount.vue"),
  },
  {
    path: "/myaccount/email",
    name: "myaccount_email",
    component: (): Promise<typeof import("*.vue")> =>
      import("@/pages/web/views/MyAccount/CustomerUpdateEmail.vue"),
  },
  {
    path: "/myaccount/password",
    name: "myaccount_password",
    component: (): Promise<typeof import("*.vue")> =>
      import("@/pages/web/views/MyAccount/CustomerUpdatePassword.vue"),
  },
  {
    path: "/myaccount/personal_data",
    name: "myaccount_personal_data",
    component: (): Promise<typeof import("*.vue")> =>
      import("@/pages/web/views/MyAccount/CustomerUpdateMasterdata.vue"),
  },
  {
    path: "/myaccount/instructor/assignments",
    name: "myaccount_instructor_assignments",
    component: (): Promise<typeof import("*.vue")> =>
      import("@/pages/web/views/MyAccount/InstructorAssignments.vue"),
  },
  {
    path: "/myaccount/course/:id(\\d+)/participationlist",
    name: "myaccount_instructor_participationlist",
    component: (): Promise<typeof import("*.vue")> =>
      import("@/pages/web/views/MyAccount/InstructorParticipationList.vue"),
  },
  {
    path: "/myaccount/course/:id(\\d+)/onlineExam/participant",
    name: "myaccount_instructor_onlineExam_participant",
    meta: { showExpandedNavigation: false },
    component: (): Promise<typeof import("*.vue")> =>
      import("@/pages/web/views/MyAccount/OnlineExamParticipant.vue"),
  },
  {
    path: "/myaccount/course/:id(\\d+)/onlineExam/instructor",
    name: "myaccount_instructor_onlineExam_instructor",
    meta: { showExpandedNavigation: false },
    component: (): Promise<typeof import("*.vue")> =>
      import("@/pages/web/views/MyAccount/OnlineExamInstructor.vue"),
  },
  {
    path: "/myaccount/pinboard",
    name: "myaccount_pinboard",
    component: (): Promise<typeof import("*.vue")> =>
      import("@/pages/web/views/MyAccount/Pinboard.vue"),
  },
  {
    path: "/myaccount/documents",
    name: "myaccount_instructor_documents",
    component: (): Promise<typeof import("*.vue")> =>
      import("@/pages/web/views/MyAccount/InstructorDocuments.vue"),
  },
  {
    path: "/myaccount/bookings",
    name: "myaccount_bookings",
    component: (): Promise<typeof import("*.vue")> =>
      import("@/pages/web/views/MyAccount/Bookings.vue"),
  },
  {
    path: "/myaccount/pmc",
    name: "myaccount_pmcs",
    component: (): Promise<typeof import("*.vue")> =>
      import("@/pages/web/views/MyAccount/PMC.vue"),
  },
  {
    path: "/myaccount/feedback",
    name: "myaccount_feedback",
    component: (): Promise<typeof import("*.vue")> =>
      import("@/pages/web/views/MyAccount/Feedback.vue"),
  },
  {
    path: "/myaccount/infomail",
    name: "myaccount_infomail",
    component: (): Promise<typeof import("*.vue")> =>
      import("@/pages/web/views/MyAccount/CustomerUpdateNewsletter.vue"),
  },
];
