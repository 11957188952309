import { Expose } from "class-transformer";
import CourseCategory from "../CourseCategory/courseCategory";
import { CourseSortingCountryType } from "../CourseCategory/enum";
import CourseCategoryBlock from "../CourseCategoryBlock/courseCategoryBlock";
import { Entity } from "../entity";

export enum CourseSortingCountryTypeEnum {
  ALL = "ALL",
  DEU = "DEU",
  AUT = "AUT",
}

export default class CourseSorting extends Entity {
  @Expose() category: CourseCategory = new CourseCategory();
  @Expose() block: CourseCategoryBlock | null = new CourseCategoryBlock();
  @Expose() country: CourseSortingCountryType | null =
    CourseSortingCountryTypeEnum.ALL as unknown as CourseSortingCountryType;
}
