import Course from "@/entities/Course/course";
import User from "@/entities/User/user";
import { AxiosResponse } from "axios";
import Service from ".";
import {
  UserCountryType,
  UserCountryTelType,
  UserProfessionType,
  UserSalutationType,
  UserQualificationType,
  UserTitleType,
  UserType,
} from "@/entities/User/enum";
import { CollectionParameter } from "@/entities/collection";
import PMC from "@/entities/PMC/pmc";
import Booking from "@/entities/Booking/booking";

export default class CustomerService {
  static service = new Service(User);

  public static collection(
    params?: CollectionParameter
  ): Promise<AxiosResponse> {
    return this.service.collection(`/api/customers`, {
      params: params,
    });
  }

  public static get(id: number): Promise<AxiosResponse> {
    return this.service.get(`/api/customers/${id}`);
  }

  public static getInstructorAssignments(id: number): Promise<AxiosResponse> {
    return this.service.collection(
      `/api/customers/${id}/instructorAssignments`,
      {},
      Course
    );
  }

  public static getActivePMC(id: number): Promise<AxiosResponse> {
    return this.service.get(`/api/customers/${id}/activePMC`, {}, PMC);
  }

  public static getUpcomingBookingsWithCases(
    id: number
  ): Promise<AxiosResponse> {
    return this.service.collection(
      `/api/customers/${id}/upcomingBookingsWithCases`,
      {},
      Booking
    );
  }

  public static getByApmId(id: number): Promise<AxiosResponse> {
    return this.service.get(`/api/customers/apm_id/${id}`);
  }

  public static getByEmail(email: string): Promise<AxiosResponse> {
    return this.service.plain(`/api/customers/email/${email}`);
  }

  public static getBookings(id: number): Promise<AxiosResponse> {
    return this.service.collection(
      `/api/customers/${id}/bookings`,
      {},
      Booking
    );
  }

  public static getPMCs(id: number): Promise<AxiosResponse> {
    return this.service.collection(`/api/customers/${id}/pmcs`, {}, PMC);
  }

  public static getRecommendations(id: number): Promise<AxiosResponse> {
    return this.service.get(`/api/customers/${id}/recommendations`);
  }

  public static fixToCourse(
    id: number,
    course: Course
  ): Promise<AxiosResponse> {
    return this.service.post(
      `/api/customers/${id}/instructorAssignments/${course.id}/fix`
    );
  }

  public static assignToCourse(
    id: number,
    course: Course
  ): Promise<AxiosResponse> {
    return this.service.post(
      `/api/customers/${id}/instructorAssignments/${course.id}/apply`
    );
  }

  public static unassignFromCourse(
    id: number,
    course: Course
  ): Promise<AxiosResponse> {
    return this.service.delete(
      `/api/customers/${id}/instructorAssignments/${course.id}/apply`
    );
  }

  public static passwordRecoveryRecover(
    code: string,
    data: User
  ): Promise<AxiosResponse> {
    return this.service.post(
      `/api/customers/passwordRecovery/recover/${code}`,
      data
    );
  }

  public static accountRecoveryRequest(data: User): Promise<AxiosResponse> {
    return this.service.post(`/api/customers/accountRecovery/request`, data);
  }

  public static passwordRecoveryRequest(data: User): Promise<AxiosResponse> {
    return this.service.post(`/api/customers/passwordRecovery/request`, data);
  }

  public static create(data: User): Promise<AxiosResponse> {
    return this.service.post(`/api/customers`, data);
  }

  public static update(id: number, data: User): Promise<AxiosResponse> {
    return this.service.patch(`/api/customers/${id}`, data);
  }

  public static delete(id: number): Promise<AxiosResponse> {
    return this.service.delete(`/api/customers/${id}`);
  }

  public static getTypes(): Promise<AxiosResponse> {
    return this.service.enum(UserType, `/api/customers/enum/type`);
  }

  public static getTitles(): Promise<AxiosResponse> {
    return this.service.enum(UserTitleType, `/api/customers/enum/title`);
  }

  public static getProfessions(): Promise<AxiosResponse> {
    return this.service.enum(
      UserProfessionType,
      `/api/customers/enum/profession`
    );
  }

  public static getSalutations(): Promise<AxiosResponse> {
    return this.service.enum(
      UserSalutationType,
      `/api/customers/enum/salutation`
    );
  }

  public static getCountries(): Promise<AxiosResponse> {
    return this.service.enum(UserCountryType, `/api/customers/enum/country`);
  }

  public static getCountriesTel(): Promise<AxiosResponse> {
    return this.service.enum(
      UserCountryTelType,
      `/api/customers/enum/countryTel`
    );
  }

  public static getQualifications(): Promise<AxiosResponse> {
    return this.service.enum(
      UserQualificationType,
      `/api/customers/enum/qualification`
    );
  }
}
