import Vue from "vue";

import Router from "vue-router";
import routes from "./routes";
import authModule from "@/store/modules/auth";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: routes,
});

router.beforeEach(async (to, from, next) => {
  const authRequired = !to.meta?.public || false;
  const showRegisterButton = to.meta?.showRegisterButton || true;
  const isAuthenticated = authModule.isLoggedIn;

  // trying to access a restricted page + not logged in
  // redirect to login page
  if (authRequired && !isAuthenticated) {
    next({
      name: "login_check",
      query: { redirect: to.path },
      params: { showRegisterButton: showRegisterButton },
    });
  } else {
    next();
  }
});

export default router;
