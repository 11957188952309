import { Expose } from "class-transformer";
import CourseCategoryBlock from "../CourseCategoryBlock/courseCategoryBlock";
import { Entity } from "../entity";

enum CourseCategoryType {
  MIDWIFE = "MIDWIFE",
  DOCTOR = "DOCTOR",
}

export enum CourseCategorySortType {
  BY_BLOCK = "BY_BLOCK",
  BY_DATE = "BY_DATE",
  BY_NAME = "BY_NAME",
}

export default class CourseCategory extends Entity {
  @Expose() name!: string;
  @Expose() position = 0;
  @Expose() type!: CourseCategoryType;
  @Expose() sort_by: CourseCategorySortType[] = [];
  @Expose() description!: string;
  @Expose() is_public = true;
  @Expose() course_category_blocks: CourseCategoryBlock[] = [];
}
