
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faHome,
  faPhoneAlt,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import Login from "@/pages/web/views/Login.vue";
import User from "@/entities/User/user";
import authModule from "@/store/modules/auth";
import NestedMenu from "@/components/NestedMenu.vue";

library.add(faHome, faPhoneAlt, faEnvelope);

@Component({
  name: "Navigation",
  components: {
    Login,
    NestedMenu,
  },
})
export default class Navigation extends Vue {
  @Prop({ required: true })
  readonly user!: User;

  showExpandedNavigation = true;

  get name(): string {
    if (this.user && this.isLoggedIn) {
      return `${this.user.firstname} ${this.user.name}`;
    }

    return "Mein Konto";
  }

  get isLoggedIn(): boolean {
    return authModule.isLoggedIn;
  }

  loading = false;
  isNavigationOpened = false;
  login = false;
  drawer = false;
  showAppBar = false;
  navigation: Array<unknown> = [
    {
      id: 1,
      name: "Fortbildung buchen",
      items: [
        { id: 10, name: "• Hebammen", href: { name: "courses_midwife" } },
        { id: 11, name: "• Ärzte*Innen", href: { name: "courses_doctor" } },
        {
          id: 12,
          name: "• Vor-Ort Fortbildungen",
          href: { name: "courses_onsite" },
        },
        {
          id: 13,
          name: "• Inhouse Fortbildungen",
          href: { name: "courses_inhouse" },
        },
        {
          id: 14,
          name: "• ProMedicoCard",
          items: [
            {
              id: 140,
              name: "• Was ist die ProMedicoCard",
              href: {
                path: "https://www.pro-medico-fortbildung.de/pdf/01.10_Was_ist_die_ProMedicoCard.pdf",
                target: "_blank",
              },
            },
            {
              id: 141,
              name: "• Bedingungen",
              href: {
                path: "https://www.pro-medico-fortbildung.de/pdf/01.20_Bedingungen_der_ProMedicoCard.pdf",
                target: "_blank",
              },
            },
            {
              id: 142,
              name: "• ProMedicoCard buchen",
              href: { name: "myaccount_pmcs" },
            },
          ],
        },
        {
          id: 15,
          name: "• Fortbildungsgebühren",
          items: [
            {
              id: 150,
              name: "• Hebammen",
              href: {
                path: "https://www.pro-medico-fortbildung.de/pdf/07.15_Kursgebuehren_Hebammen.pdf",
                target: "_blank",
              },
            },
            {
              id: 151,
              name: "• Ärzte*innen",
              href: {
                path: "https://www.pro-medico-fortbildung.de/pdf/07.20_Kursgebuehren_Aerzte.pdf",
                target: "_blank",
              },
            },
          ],
        },
        {
          id: 16,
          name: "• Teilnahmebedingungen",
          href: {
            path: "https://www.pro-medico-fortbildung.de/pdf/AGB_ProMedico.pdf",
            target: "_blank",
          },
        },
        {
          id: 17,
          name: "• Behandlungsfälle",
          items: [
            {
              id: 170,
              name: "• Musterfall",
              href: {
                path: "https://www.pro-medico-fortbildung.de/pdf/07.14.2_Behandlungsfaelle_Musterfall.pdf",
                target: "_blank",
              },
            },
            {
              id: 171,
              name: "• Erstellhinweise",
              href: {
                path: "https://www.pro-medico-fortbildung.de/pdf/07.14_Hinweise_zu_Behandlungsfaellen.pdf",
                target: "_blank",
              },
            },
          ],
        },
        {
          id: 18,
          name: "• Seminarversicherung",
          href: {
            path: "https://www.google.com/search?&q=Seminar-Versicherung",
            target: "_blank",
          },
        },
        {
          id: 19,
          name: "• Planungshilfen",
          items: [
            {
              id: 190,
              name: "• DB Veranstaltungsticket",
              items: [
                {
                  id: 1900,
                  name: "• Information",
                  href: {
                    path: "https://www.veranstaltungsticket-bahn.de/onepager?l=de&v=2024&event=17947",
                    target: "_blank",
                  },
                },
                {
                  id: 1901,
                  name: "• Online Buchung",
                  href: {
                    path: "https://www.veranstaltungsticket-bahn.de/?event=17947&language=de",
                    target: "_blank",
                  },
                },
              ],
            },
            {
              id: 191,
              name: "• Routenplaner",
              href: {
                path: "https://www.pro-medico-fortbildung.de/Routenplaner.php",
                target: "_blank",
              },
            },
            {
              id: 192,
              name: "• Ferienkalender",
              href: {
                path: "https://www.schulferien.org/",
                target: "_blank",
              },
            },
          ],
        },
      ],
    },
    {
      id: 2,
      name: "Fortbildungen",
      items: [
        {
          id: 20,
          name: "• Pro Medico Veranstaltungsorte",
          href: {
            path: "https://pro-medico-fortbildung.de/PM_Veranstaltungsorte.php",
            target: "_self",
          },
        },
      ],
    },
    {
      id: 3,
      name: "ProMedicoCard",
      items: [
        {
          id: 30,
          name: "• Was ist die ProMedicoCard",
          href: {
            path: "https://www.pro-medico-fortbildung.de/pdf/01.10_Was_ist_die_ProMedicoCard.pdf",
            target: "_blank",
          },
        },
        {
          id: 31,
          name: "• Bedingungen",
          href: {
            path: "https://www.pro-medico-fortbildung.de/pdf/01.20_Bedingungen_der_ProMedicoCard.pdf",
            target: "_blank",
          },
        },
        {
          id: 32,
          name: "• ProMedicoCard buchen",
          href: { name: "myaccount_pmcs" },
        },
      ],
    },
  ];

  onNavigationMenuToggle(val: boolean): void {
    this.isNavigationOpened = val;
  }

  onLogout(): void {
    authModule.logout();
    this.$router.push({ name: "home" });
  }

  onScroll(): void {
    this.showAppBar = window.pageYOffset == 0;
  }

  @Watch("$route", { immediate: true })
  onRouteChanged() {
    this.showExpandedNavigation = this.$route.meta?.showExpandedNavigation;
  }

  mounted(): void {
    this.showAppBar = window.pageYOffset == 0;
    document.addEventListener("scroll", this.onScroll);
  }
}
