
import Component from "vue-class-component";
import { Prop, Vue, Watch } from "vue-property-decorator";

@Component({ name: "NestedMenu" })
export default class NestedMenu extends Vue {
  @Prop() icon!: string;
  @Prop() nav!: unknown;

  @Prop({ default: false }) isOffsetX!: boolean;
  @Prop({ default: true }) isOffsetY!: boolean;
  @Prop({ default: false }) isSubMenu!: boolean;
  @Prop({ default: "scale-transition" }) transition!: string;

  menuState = false;
  hover = false;

  changeState(state: boolean): void {
    this.hover = state;
    this.$emit("subMenu", state);
  }

  @Watch("hover")
  onHoverChanged(): void {
    setTimeout(() => {
      this.menuState = this.hover;
    }, 100);
  }
}
