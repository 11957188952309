
import Vue from 'vue';
import Component from "vue-class-component";
import cookieModule from "@/store/modules/cookie";

@Component({
  name: "CookieConsent",
})
export default class CookieConsent extends Vue {
  loading = false;
  error: string | null = null;

  get accepted(): boolean {
    return cookieModule.hasAccepted;
  }

  accept(): void {
    cookieModule.accept();
    console.log(cookieModule.hasAccepted);
  }
}
