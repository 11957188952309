import { Expose, Type } from "class-transformer";
import Booking from "../Booking/booking";
import CourseTemplate from "../CourseTemplate/courseTemplate";
import { Entity } from "../entity";
import Location from "../Location/location";
import User from "../User/user";
import CourseADiplom from "./courseADiplom";
import CourseBookable from "./courseBookable";
import CourseCertificate from "./courseCertificate";
import CourseDate from "./courseDate";
import CourseDetail from "./courseDetail";
import CourseInhouse from "./courseInhouse";
import CourseInvoice from "./courseInvoice";
import CourseName from "./courseName";
import CourseOnline from "./courseOnline";
import CourseOnSite from "./courseOnSite";
import CoursePriceStructure from "./coursePriceStructure";
import CourseSorting from "./courseSorting";
import CourseOnlineExam from "./courseOnlineExam";

export enum ConfigType {
  COURSE = "COURSE",
  INHOUSE = "INHOUSE",
  ONSITE = "ONSITE",
  ADIPLOM = "ADIPLOM",
}

export enum CourseType {
  STANDARD = "STANDARD",
  EXAM = "EXAM",
}

export enum PresenceType {
  PRESENCE = "PRESENCE",
  ONLINE = "ONLINE",
}

export enum CountryType {
  DE = "DE",
  AT = "AT",
}

export default class Course extends Entity {
  @Type(() => Booking)
  @Expose()
  bookings: Booking[] = [];

  @Type(() => Booking)
  @Expose()
  valid_bookings: Booking[] = [];

  @Expose() config_type!: ConfigType;
  @Expose() color!: string;
  @Expose() bookable: CourseBookable = new CourseBookable();
  @Expose() name!: string;
  @Expose() name_alternative: CourseName | null = null;
  @Expose() presence!: PresenceType;
  @Expose() location: Location | null = null;
  @Expose() online: CourseOnline | null = null;
  @Expose() internal_id!: string;
  @Expose() is_active = true;
  @Expose() is_online_customers = true;
  @Expose() is_online_instructors = true;
  @Expose() is_visible_all_countries = false;
  @Expose() has_booking_confirmation = true;
  @Expose() has_invoice = true;
  @Expose() has_theoretical_exam = false;
  @Expose() has_practical_exam = false;
  @Expose() invoice_country_type!: CountryType;
  @Expose() course_date: CourseDate | null = null;
  @Expose() course_price_structure: CoursePriceStructure | null = null;
  @Expose() course_detail: CourseDetail | null = null;
  @Expose() course_a_diplom: CourseADiplom | null = null;
  @Expose() course_inhouse: CourseInhouse | null = null;
  @Expose() course_on_site: CourseOnSite | null = null;
  @Expose() template: CourseTemplate | null = null;
  @Expose() internal_notes: string | null = null;
  @Expose() status!: string;
  @Expose() certificate: CourseCertificate | null = new CourseCertificate();
  @Expose() course_invoice: CourseInvoice | null = null;
  @Expose() pdf_participantlist_link!: string;
  @Expose() pdf_participantlist_admin_link!: string;
  @Expose() course_online_exam: CourseOnlineExam | null = null;

  @Type(() => CourseSorting)
  @Expose()
  sortings: CourseSorting[] = [];

  @Type(() => User)
  @Expose()
  applied_instructors: User[] = [];

  @Type(() => User)
  @Expose()
  fixed_instructors: User[] = [];

  recreateInvoices = false;

  private addCategoriesByTemplate = true;

  get isCategoriesByTemplate(): boolean {
    return this.addCategoriesByTemplate;
  }

  configureCourse(): void {
    this.config_type = ConfigType.COURSE;
    this.certificate = new CourseCertificate();
    this.course_date = new CourseDate();
    this.course_price_structure = new CoursePriceStructure();
    this.course_detail = new CourseDetail();
    this.addCategoriesByTemplate = true;
    this.course_inhouse = null;
    this.course_on_site = null;
    this.course_a_diplom = null;
    this.location = null;
    this.course_invoice = new CourseInvoice();
  }

  configurePresence(location: Location | null = null): void {
    this.presence = PresenceType.PRESENCE;
    this.online = null;
    this.location = location ?? new Location();
  }

  configureOnline(online: CourseOnline | null = null): void {
    this.course_detail = new CourseDetail();
    this.presence = PresenceType.ONLINE;
    this.online = online ?? new CourseOnline();
    this.location = null;

    if (this.course_detail) {
      this.course_detail.max_participants = 300;
    }
  }

  configureOnSite(onsite: CourseOnSite | null = null): void {
    this.config_type = ConfigType.ONSITE;
    this.certificate = new CourseCertificate();
    this.course_date = new CourseDate();
    this.course_price_structure = new CoursePriceStructure();
    this.course_detail = new CourseDetail();
    this.course_inhouse = null;
    this.course_on_site = onsite || new CourseOnSite();
    this.course_a_diplom = null;
    this.location = new Location();
    this.course_invoice = new CourseInvoice();
  }

  configureInhouse(inhouse: CourseInhouse | null = null): void {
    this.config_type = ConfigType.INHOUSE;
    this.certificate = new CourseCertificate();
    this.course_date = new CourseDate();
    this.course_price_structure = new CoursePriceStructure();
    this.course_detail = new CourseDetail();
    this.course_inhouse = inhouse || new CourseInhouse();
    this.course_on_site = null;
    this.course_a_diplom = null;
    this.location = null;
    this.course_invoice = new CourseInvoice();
  }

  configureADiplom(adiplom: CourseADiplom | null = null): void {
    this.config_type = ConfigType.ADIPLOM;
    this.certificate = null;
    this.course_date = null;
    this.course_price_structure = null;
    this.course_detail = null;
    this.course_inhouse = null;
    this.course_on_site = null;
    this.course_a_diplom = adiplom || new CourseADiplom();
    this.location = null;
    this.course_invoice = null;
  }

  public static getSchema(): Record<string, unknown> {
    return {
      "buchbar?": { type: String, required: true, prop: "is_online_customers" },
      Kursnummer: { type: String, required: true, prop: "internal_id" },
      Kursbezeichnung: { type: String, required: true, prop: "name" },
      Kursvorlage: {
        type: String,
        required: true,
        prop: "course_template",
      },
      Typ: { type: String, required: true, prop: "type" },
      Location: { type: String, required: true, prop: "location" },
      Rechnungsland: { type: String, required: true, prop: "invoice_country" },
      Zahlungsziel: { type: Date, required: true, prop: "payment_date" },
      Rechnungsversand: { type: Date, required: true, prop: "invoice_date" },
      "Kurstag 1": { type: String, required: true, prop: "day1" },
      "Kurstag 2": { type: String, required: false, prop: "day2" },
      "Kurstag 3": { type: String, required: false, prop: "day3" },
      Preis: {
        type: String,
        required: true,
        prop: "price",
      },
      "Preis - gültig bis": {
        type: Date,
        required: true,
        prop: "price_valid_until",
      },
      "Preis (PMC)": { type: String, required: false, prop: "price_pmc" },
      "Preis (PMC) - gültig bis": {
        type: Date,
        required: true,
        prop: "price_pmc_valid_until",
      },
      "Preis (Sonderpreis)": {
        type: String,
        required: false,
        prop: "price_special",
      },
      "Preis (Sonderpreis) - gültig bis": {
        type: Date,
        required: false,
        prop: "price_special_valid_until",
      },
    };
  }
}
