
import User from "@/entities/User/user";
import Navigation from "@/pages/web/views/Navigation.vue";
import Impersonate from "@/pages/web/components/Impersonate/Impersonate.vue";
import CustomerService from "@/services/customer.service";
import { Vue, Component } from "vue-property-decorator";
import authModule from "@/store/modules/auth";
import CookieConsent from "@/pages/web/helpers/CookieConsent.vue"

@Component({
  name: "App",
  components: {
    Navigation,
    Impersonate,
    CookieConsent
  },
})
export default class App extends Vue {
  loading = false;
  error!: string | null;
  user: User | null = null;

  mounted(): void {
    if (authModule.id) {
      this.loading = true;
      CustomerService.get(authModule.id)
        .then((response) => {
          this.user = response.data;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
}
