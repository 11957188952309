
import Vue from "vue";
import Component from "vue-class-component";

@Component({
  name: "Impersonate",
})
export default class Impersonate extends Vue {
  get isImpersonated(): boolean {
    return this.$cookies.isKey("IMPERSONATE");
  }

  removeImpersonate(): void {
    this.$cookies.remove("IMPERSONATE");
    this.$router.go(0);
  }
}
