
import { Vue, Component, Prop } from "vue-property-decorator";
import authModule from "@/store/modules/auth";

type AuthForm = {
  path: string;
  user: {
    username: string;
    password: string;
  };
};

@Component({ name: "Login" })
export default class Login extends Vue {
  loading = false;
  error: string | null = null;

  @Prop({ default: false })
  readonly dialog!: boolean;

  @Prop({ default: false })
  readonly showRegisterButton!: boolean;

  showPassword = false;
  auth: AuthForm = {
    path: "/auth/user/login",
    user: {
      username: this.$route.query.email as string,
      password: "",
    },
  };

  redirect: string = this.$route.query.redirect as string;

  get isLoggedIn(): boolean {
    return authModule.isLoggedIn && authModule.getAuth?.data != null;
  }

  mounted(): void {
    if (this.isLoggedIn) {
      this.$router.push({ name: "myaccount" });
    }
  }

  toRegistration(): void {
    this.$emit("close");
    this.$router.push({ name: "registration" });
  }

  toAccountRecovery(): void {
    this.$emit("close");
    this.$router.push({
      name: "recovery_account_request",
      query: { email: this.auth.user.username },
    });
  }

  toPasswordRecovery(): void {
    this.$emit("close");
    this.$router.push({
      name: "recovery_password_request",
      query: { email: this.auth.user.username },
    });
  }

  onSubmit(): void {
    this.loading = true;

    authModule
      .login(this.auth)
      .then(
        () => {
          this.$emit("close");
          if (this.redirect) {
            this.$router.push({ path: this.redirect });
          } else {
            this.$router.push({ name: "myaccount" });
          }
        },
        (error) => {
          console.log(error);
          this.error = error.response.data.message;
        }
      )
      .finally(() => {
        this.loading = false;
      });
  }
}
