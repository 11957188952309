import {
  Action,
  Module,
  Mutation,
  VuexModule,
  getModule,
} from "vuex-module-decorators";
import store from "@/store";

type CookieConsent = {
  accepted: boolean;
};

@Module({
  name: "cookie",
  namespaced: true,
  store,
  dynamic: true,
})
class AuthModule extends VuexModule {
  public static readonly localStorageName = "promedico-ts-cookies";

  cookieConsent: CookieConsent | null = this.getStorage();

  private getStorage(): CookieConsent | null {
    try {
      const storage = localStorage.getItem(AuthModule.localStorageName);
      if (!storage) {
        return null;
      }

      return JSON.parse(storage) as CookieConsent;
    }
    catch (err) {
      return null;
    }
  }

  get hasAccepted(): boolean {
    return this.cookieConsent?.accepted || false;
  }

  @Action
  public accept(): void {
    this.context.commit("mutation", { accepted: true });
  }

  @Mutation
  public mutation(cookieConsent: CookieConsent): void {
    this.cookieConsent = cookieConsent;

    localStorage.setItem(
      AuthModule.localStorageName,
      JSON.stringify(this.cookieConsent)
    );
  }
}

export default getModule(AuthModule);
