export default [
  {
    path: "/onlineExam/instructor/:uuid",
    name: "online_exam_instructor",
    meta: { showExpandedNavigation: false },
    component: (): Promise<typeof import("*.vue")> =>
      import("@/pages/web/views/OnlineExam/Instructor.vue"),
  },
  {
    path: "/onlineExam/instructor/:uuid/result",
    name: "online_exam_instructor_result",
    meta: { showExpandedNavigation: false },
    component: (): Promise<typeof import("*.vue")> =>
      import("@/pages/web/views/OnlineExam/InstructorExamResult.vue"),
  },
  {
    path: "/onlineExam/participant/:uuid",
    name: "online_exam_participant",
    meta: { showExpandedNavigation: false },
    component: (): Promise<typeof import("*.vue")> =>
      import("@/pages/web/views/OnlineExam/Participant.vue"),
  },
  {
    path: "/onlineExam/participant/:uuid/result",
    name: "online_exam_participant_result",
    meta: { showExpandedNavigation: false },
    component: (): Promise<typeof import("*.vue")> =>
      import("@/pages/web/views/OnlineExam/ParticipantResult.vue"),
  },
];
