export default [
  {
    path: "",
    name: "home",
    beforeEnter() {
      window.location.replace("https://www.pro-medico-fortbildung.de");
    },
    meta: { public: true },
  },
  {
    path: "/check",
    name: "login_check",
    component: (): Promise<typeof import("*.vue")> =>
      import("@/pages/web/views/LoginCheck.vue"),
    meta: { public: true },
  },
  {
    path: "/login",
    name: "login",
    component: (): Promise<typeof import("*.vue")> =>
      import("@/pages/web/views/Login.vue"),
    meta: { public: true },
  },
  {
    path: "/registration",
    name: "registration",
    component: (): Promise<typeof import("*.vue")> =>
      import("@/pages/web/views/Registration.vue"),
    meta: { public: true },
  },
  {
    path: "/registration/success",
    name: "registration_success",
    component: (): Promise<typeof import("*.vue")> =>
      import("@/pages/web/views/Registration/Success.vue"),
    meta: { public: true },
  },
  {
    path: "/registration/verify",
    name: "registration_verify",
    component: (): Promise<typeof import("*.vue")> =>
      import("@/pages/web/views/Registration/Verify.vue"),
    meta: { public: true },
  },
  {
    path: "/recovery/account/request",
    name: "recovery_account_request",
    component: (): Promise<typeof import("*.vue")> =>
      import("@/pages/web/views/AccountRecovery/Request.vue"),
    meta: { public: true },
  },
  {
    path: "/recovery/password/request",
    name: "recovery_password_request",
    component: (): Promise<typeof import("*.vue")> =>
      import("@/pages/web/views/PasswordRecovery/Request.vue"),
    meta: { public: true },
  },
  {
    path: "/recovery/password/recover/:code",
    name: "recovery_password_recover",
    component: (): Promise<typeof import("*.vue")> =>
      import("@/pages/web/views/PasswordRecovery/Recover.vue"),
    meta: { public: true },
  },
  {
    path: "/impersonate",
    name: "impersonate",
    component: (): Promise<typeof import("*.vue")> =>
      import("@/pages/web/views/Impersonate.vue"),
  },
];
